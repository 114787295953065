<template>
  <form class="profile-edit" @submit.prevent="onSubmit" novalidate autocomplete="off">
    <section class="profile-edit__section">
      <UiTitle :title="$t('profile.securityData.title')" />

      <div class="profile-edit__section__grid">
        <div>
          <UiLabel id="current-password" :label="$t('profile.securityData.label.oldPassword')" />
          <v-text-field
            v-model.trim="oldPassword"
            id="current-password"
            :error-messages="oldPasswordErrors"
            autocomplete="current-password"
            solo
            flat
            outlined
            :placeholder="$t('profile.securityData.placeholder.oldPassword')"
            :type="showOldPassword ? 'text' : 'password'"
            :append-icon="showOldPassword ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
            @click:append="showOldPassword = !showOldPassword"
          />
        </div>
        <div>
          <UiLabel id="new-password" :label="$t('profile.securityData.label.newPassword')" />
          <v-text-field
            v-model.trim="newPassword"
            id="new-password"
            :error-messages="newPasswordErrors"
            autocomplete="new-password"
            solo
            flat
            outlined
            :placeholder="$t('profile.securityData.placeholder.newPassword')"
            :type="showNewPassword ? 'text' : 'password'"
            :append-icon="showNewPassword ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
            @click:append="showNewPassword = !showNewPassword"
          />
        </div>
        <div>
          <UiLabel id="password-confirmation" :label="$t('profile.securityData.label.newPasswordConfirmation')" />
          <v-text-field
            v-model.trim="newPasswordConfirmation"
            id="password-confirmation"
            :error-messages="newPasswordConfirmationErrors"
            autocomplete="new-password"
            solo
            flat
            outlined
            :placeholder="$t('profile.securityData.placeholder.newPasswordConfirmation')"
            :type="showNewPasswordConfirmation ? 'text' : 'password'"
            :append-icon="showNewPasswordConfirmation ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
            @click:append="showNewPasswordConfirmation = !showNewPasswordConfirmation"
          />
        </div>
      </div>
    </section>
    <UiActions large centered>
      <v-btn type="submit" rounded color="primary" :loading="saving" :disabled="saving">
        {{ $t('button.save') }}
      </v-btn>
    </UiActions>
  </form>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { minLength, sameAs, required } from 'vuelidate/lib/validators'
import { mdiAccountOutline, mdiWeb, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { locales } from '@/config/locales.config'
import UiLabel from '@/components/UI/Label.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiActions from '@/components/UI/Actions.vue'

export default {
  name: 'ProfileEditSecurity',
  components: {
    UiLabel,
    UiTitle,
    UiActions,
  },
  props: {
    saving: {
      type: Boolean,
      required: true,
    },
    resetData: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [validationMixin],
  data: () => ({
    icons: {
      mdiAccountOutline,
      mdiWeb,
      mdiEyeOutline,
      mdiEyeOffOutline,
    },
    locales,
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
    showOldPassword: false,
    showNewPassword: false,
    showNewPasswordConfirmation: false,
  }),

  watch: {
    resetData: {
      handler() {
        if (this.resetData) {
          this.oldPassword = ''
          this.newPassword = ''
          this.newPasswordConfirmation = ''
          this.$v.$reset()
        }
      },
      deep: true,
    },
  },
  computed: {
    oldPasswordErrors() {
      const errors = []
      if (!this.$v.oldPassword.$dirty) return errors
      !this.$v.oldPassword.required && errors.push(this.$t('error.required'))
      return errors
    },
    newPasswordErrors() {
      const errors = []
      if (!this.$v.newPassword.$dirty) return errors
      !this.$v.newPassword.minLength &&
        errors.push(
          this.$t('error.passwordLength', {
            minLength: this.$v.newPassword.$params.minLength.min,
          })
        )
      return errors
    },
    newPasswordConfirmationErrors() {
      const errors = []
      if (!this.$v.newPasswordConfirmation.$dirty) return errors
      !this.$v.newPasswordConfirmation.sameAs && errors.push(this.$t('error.passwordConfirmation'))
      return errors
    },
  },
  methods: {
    onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit('save', { oldPassword: this.oldPassword, newPassword: this.newPassword })
      }
    },
  },
  validations() {
    return {
      oldPassword: {
        required,
      },
      newPassword: {
        minLength: minLength(8),
      },
      newPasswordConfirmation: {
        sameAs: sameAs('newPassword'),
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.profile-edit {
  &__section {
    @apply tw-mt-12;
    background-color: #f1f5f9;

    &:first-child {
      @apply tw-mt-0;
    }

    &__grid {
      @apply tw-grid sm:tw-grid-cols-2 tw-gap-6 tw-w-full tw-mt-6;
    }
  }
}
</style>
