<template>
  <v-overlay :value="updating" v-if="updating" absolute>
    <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
  </v-overlay>
  <div v-else class="profile">
    <div v-if="!isInternalModule" class="profile__back">
      <v-btn class="profile__back__button" color="black" icon @click="openExternalLink">
        <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
      </v-btn>
    </div>
    <UiBanner
      class="tw-py-6"
      :class="{ 'tw-pt-2': !isInternalModule }"
      :title="$t('profile.title')"
      :subtitle="$t('profile.subtitle')"
    />
    <v-tabs class="tw-flex-none" v-model="tabs">
      <v-tab class="tw-px-6">
        {{ $t('profile.tabs.general') }}
      </v-tab>
      <v-tab class="tw-px-6">
        {{ $t('profile.tabs.password') }}
      </v-tab>
    </v-tabs>
    <UiContainer class="profile__container" large with-actions>
      <v-tabs-items class="tw-my-4" v-model="tabs">
        <v-tab-item :key="0">
          <ProfileEditGeneral :user-my-self="userMySelf" :saving="saving" :reset-data="resetData" @save="updateUser" />
        </v-tab-item>
        <v-tab-item :key="1">
          <ProfileEditSecurity :saving="saving" :reset-data="resetData" @save="onSavePassword" />
        </v-tab-item>
      </v-tabs-items>
    </UiContainer>
  </div>
</template>

<script>
import { redirectToExternalModule } from '@/utils/auth.util'
import { mapActions, mapState } from 'vuex'
import { mdiSquareEditOutline, mdiArrowLeft } from '@mdi/js'
import UiBanner from '@/components/UI/Banner.vue'
import UiContainer from '@/components/UI/Container.vue'
import ProfileEditGeneral from '@/views/Profile/Edit/General.vue'
import ProfileEditSecurity from '@/views/Profile/Edit/Security.vue'

export default {
  name: 'UserProfile',
  components: {
    UiBanner,
    UiContainer,
    ProfileEditGeneral,
    ProfileEditSecurity,
  },
  data: () => ({
    redirectToExternalModule,
    icons: {
      mdiSquareEditOutline,
      mdiArrowLeft,
    },
    saving: false,
    resetData: false,
    tabs: null,
  }),
  computed: {
    ...mapState({
      modules: state => state.backoffice.modules,
      updating: state => state.backoffice.updating,
      userMySelf: state => state.users.userMySelf,
    }),
    isInternalModule() {
      return this.currentModule?.product === 'gtrsuite_v3'
    },
    currentModule() {
      return this.modules.find(m => m.module === this.$route.query.module) || null
    },
  },
  async created() {
    this.setUpdating(true)
    await this.getUserMySelf()
    this.setUpdating(false)
  },
  methods: {
    ...mapActions({
      setUpdating: 'backoffice/setUpdating',
      userUpdatePassword: 'backoffice/userUpdatePassword',
      setAlert: 'backoffice/setAlert',

      patchUser: 'users/patchUser',
      getUserMySelf: 'users/getUserMySelf',
    }),
    openExternalLink() {
      const module = this.currentModule || this.modules.find(m => m.default)
      redirectToExternalModule(module, this.$route.params.clientId)
    },
    async updateUser(user) {
      this.saving = true
      this.resetData = false
      try {
        await this.patchUser({ params: { uuid: user.uuid }, body: user })
        this.setAlert({
          color: 'success',
          text: this.$t('profile.notification.success'),
        })
        this.saving = false
        this.resetData = true
      } catch (error) {
        this.setAlert({
          color: 'error',
          text: this.$t('error.notification.default'),
        })
        this.saving = false
      }
    },
    async onSavePassword(user) {
      this.saving = true
      this.resetData = false
      try {
        if (user.oldPassword && user.newPassword)
          await this.userUpdatePassword({ oldPassword: user.oldPassword, newPassword: user.newPassword })
        this.setAlert({
          color: 'success',
          text: this.$t('profile.notification.success'),
        })
        this.saving = false
        this.resetData = true
      } catch (error) {
        if (error.status === 400 && error.data.message === 'PERMISSION_DENIED') {
          this.setAlert({
            color: 'error',
            text: this.$t('profile.notification.wrongPassword'),
          })
        } else {
          this.setAlert({
            color: 'error',
            text: this.$t('error.notification.default'),
          })
        }
        this.saving = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.profile {
  &__back {
    @apply tw-pt-4 tw-px-4 sm:tw-px-6;
    background: map-get($material-light, 'banner', 'background');

    &__button {
      margin-left: -8px;
    }
  }
}
</style>
