<template>
  <form class="profile-edit" @submit.prevent="onSubmit" novalidate autocomplete="off">
    <section class="profile-edit__section">
      <UiTitle :title="$t('profile.personalData.title')" />

      <div class="profile-edit__section__grid">
        <div>
          <UiLabel id="firstname" :label="$t('profile.personalData.label.firstName')" />
          <v-text-field
            v-model="userModel.firstName"
            id="firstname"
            solo
            flat
            outlined
            :placeholder="$t('profile.personalData.placeholder.firstName')"
            :prepend-inner-icon="icons.mdiAccountOutline"
            hide-details
          />
        </div>
        <div>
          <UiLabel id="lastname" :label="$t('profile.personalData.label.lastName')" />
          <v-text-field
            v-model="userModel.lastName"
            id="lastname"
            solo
            flat
            outlined
            :placeholder="$t('profile.personalData.placeholder.lastName')"
            :prepend-inner-icon="icons.mdiAccountOutline"
            hide-details
          />
        </div>
        <div>
          <UiLabel id="locale" :label="$t('profile.personalData.label.locale')" />
          <v-select
            v-model="userModel.defaultLocaleIsoCode"
            id="locale"
            solo
            flat
            outlined
            :items="locales"
            item-value="codeRegion"
            :placeholder="$t('profile.personalData.placeholder.locale')"
            :prepend-inner-icon="icons.mdiWeb"
            hide-details
          />
        </div>
        <div>
          <UiLabel id="module" :label="$t('profile.personalData.label.module')" />
          <v-select
            v-model="userModel.defaultModule"
            id="module"
            solo
            flat
            outlined
            :items="modules"
            item-value="module"
            item-text="module"
            :placeholder="$t('profile.personalData.placeholder.locale')"
            :prepend-inner-icon="icons.mdiViewModule"
            hide-details
          />
        </div>
        <div>
          <UiLabel id="email" :label="$t('profile.personalData.label.email')" />
          <v-text-field
            disabled
            readonly
            v-model="userModel.email"
            id="email"
            solo
            flat
            outlined
            :prepend-inner-icon="icons.mdiAccountOutline"
            hide-details
          />
        </div>
      </div>
    </section>

    <UiActions large centered>
      <v-btn type="submit" rounded color="primary" :loading="saving" :disabled="saving">
        {{ $t('button.save') }}
      </v-btn>
    </UiActions>
  </form>
</template>

<script>
import { mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import { mdiAccountOutline, mdiWeb, mdiEyeOutline, mdiEyeOffOutline, mdiViewModule } from '@mdi/js'
import { locales } from '@/config/locales.config'
import { clone } from '@/utils/utilities.util'
import UiLabel from '@/components/UI/Label.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiActions from '@/components/UI/Actions.vue'

export default {
  name: 'ProfileEditGeneral',
  components: {
    UiLabel,
    UiTitle,
    UiActions,
  },
  props: {
    userMySelf: {
      type: Object,
      required: true,
    },
    saving: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [validationMixin],
  data: () => ({
    icons: {
      mdiAccountOutline,
      mdiWeb,
      mdiEyeOutline,
      mdiEyeOffOutline,
      mdiViewModule,
    },
    locales,
    userModel: {},
  }),
  created() {
    this.userModel = clone(this.userMySelf)
  },
  computed: {
    ...mapState({
      modules: state => state.backoffice.modules,
    }),
  },
  methods: {
    onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit('save', this.userModel)
      }
    },
  },
  validations() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.profile-edit {
  &__section {
    @apply tw-mt-12;
    background-color: #f1f5f9;

    &:first-child {
      @apply tw-mt-0;
    }

    &__grid {
      @apply tw-grid sm:tw-grid-cols-2 tw-gap-6 tw-w-full tw-mt-6;
    }
  }
}
</style>
